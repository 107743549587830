import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';

import 'config/i18n'
import { authConfig } from 'config/authConfig';
import reportWebVitals from './reportWebVitals';
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/styles/custom.scss';
import PageSpinner from 'components/ui/spinner/PageSpinner';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <React.Suspense fallback={<PageSpinner />}>
      <AuthProvider {...authConfig}>
        <App />
      </AuthProvider>
    </React.Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
