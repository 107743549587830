import axios from "axios";

import { API_BASE_URI } from 'utils/setting'
import { getAuthToken, refreshToken, login } from 'utils/auth';

const instance = axios.create({
  baseURL: API_BASE_URI,
  headers: {
    'Access-Control-Allow-Methods': 'HEAD, GET, POST, PUT, PATCH, DELETE',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
});

const errorHandler = (error: any) => {
  const statusCode = error.response?.status
  if (statusCode && statusCode === 401) {
    login()
  }

  if (statusCode && statusCode === 403) {
    refreshToken()
  }

  return Promise.reject(error)
}

instance.interceptors.request.use(async function (config) {
  const token = await getAuthToken()
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token;
  }
  return config;
}, function (error) {
  console.log(error);
  return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {

  return errorHandler(error)
});

export default instance;