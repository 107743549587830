import { User, UserManager } from 'oidc-client-ts';
import localStore from 'store2';

import { AUTHORITY, CLIENT_ID } from 'utils/setting'
import { authConfig } from 'config/authConfig';
import { pageRoutes } from 'config/router/pageRoutes';

const userManager = new UserManager(authConfig);

userManager.events.addAccessTokenExpired(() => {
    userManager.signinSilent();
});

export const signinCallback = () => {
    return userManager.signinRedirectCallback()
        .then(() => {
            window.location.replace(pageRoutes.home);
        })
        .catch(err => console.log(err));
};

export const signinSilentCallback = () => {
    return userManager.signinSilentCallback();
};

export const login = () => {
    return userManager.signinRedirect()
}

export const logout = () => {
    localStore.remove(process.env.REACT_APP_LOCAL_STORAGE_CURRENT_USER)
    return userManager.signoutRedirect()
}

export const refreshToken = () => {
    return userManager.signinSilent()
}

export const isAuthenticated = () => {
    const oidcStorage = localStore.get(`oidc.user:${AUTHORITY}:${CLIENT_ID}`)
    const user: User = oidcStorage

    return (!!user && !!user.access_token)
};

export const getAuthToken = () => userManager.getUser().then(user => user?.access_token)