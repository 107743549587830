import { useTranslation } from 'react-i18next';

const useLocalizer = (key: string, nameSpace?: string): string => {
    const { t } =  useTranslation(nameSpace);    
    
    // if(key)
    // {
    //     return (t(key.toLowerCase()));
    // }

    return (t(key));  
}
export default useLocalizer;