import React from 'react'
import { WifiOff } from 'react-bootstrap-icons'

import T from 'utils/useLocalizer'


const OfflinePage = () => {
    const resources = {
        noInternetConnectionTitle: T('error.no.internet.connection.title'),
        noInternetConnectionMsg: T('error.no.internet.connection.message')
    }

    return (
        <div id="offline-page" className='position-relative' style={{ minHeight: '100vh' }}>
            <div className='position-absolute top-50 start-50 translate-middle text-center w-100'>
                <div className='bg-primary py-5'>
                    <WifiOff size={60} className='text-light' />
                    <h4 className='text-light'>{resources.noInternetConnectionTitle}</h4>
                    <p className='text-light'>{resources.noInternetConnectionMsg}</p>
                </div>
            </div>
        </div>
    )
}

export default OfflinePage