import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export type ColorScheme = 'light' | 'dark'

export interface ThemeState {
    colorScheme: ColorScheme,
    oppositeScheme: ColorScheme
}

const themeSlice = createSlice({
    name: 'theme',
    initialState: {
        colorScheme: 'light',
        oppositeScheme: 'dark'
    } as ThemeState,
    reducers: {
        changeColorScheme(state, action: PayloadAction<ColorScheme>) {
            state.colorScheme = action.payload
        },
        toggleColorScheme(state) {
            const current = state.colorScheme
            state.colorScheme = state.oppositeScheme
            state.oppositeScheme = current
        },
    },
})

export const { changeColorScheme, toggleColorScheme } = themeSlice.actions

export default themeSlice;