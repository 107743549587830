import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IChatbot, IVisualBuilder } from 'models/chatbotModel'
import { RootState } from 'store/storeConfig'


export interface ChatbotState {
    data: IChatbot,
    trackedNodes: NodeTrackerType[]
}

type NodeTrackerType = {
    id: string,
    isNew?: boolean,
    isDirty?: boolean,
    isValid?: boolean,
}

const chatbotSlice = createSlice({
    name: 'visualBuilder',
    initialState: {
        data: {} as IChatbot,
        trackedNodes: [],
    } as ChatbotState,
    reducers: {
        addChatbotData(state, action: PayloadAction<IChatbot>) {
            const chatbot = action.payload
            state.data = chatbot
        },
        updateChatbotFlow(state, action: PayloadAction<IVisualBuilder>) {
            if (state.data) {
                state.data.flow = action.payload;
                state.data.vbGraph = JSON.stringify(action.payload)
            }
        },
        updateFormValidity(state, action: PayloadAction<NodeTrackerType>) {
            const index = state.trackedNodes?.findIndex(n => n.id === action.payload.id)
            if (index > -1) {
                const nodes = [...state.trackedNodes]
                nodes[index] = { ...nodes[index], isValid: action.payload.isValid }
                state.trackedNodes = nodes
            }
        },
        trackNewNode(state, action: PayloadAction<NodeTrackerType>) {
            if (!state.trackedNodes.some(t => t.id === action.payload.id)) {
                state.trackedNodes?.push(action.payload)
            }
        },
        updateTrackedNode(state, action: PayloadAction<NodeTrackerType>) {
            const index = state.trackedNodes?.findIndex(n => n.id === action.payload.id)
            if (index > -1) {
                const nodes = [...state.trackedNodes]
                nodes[index] = { ...nodes[index], ...action.payload }
                state.trackedNodes = nodes
            }
        },
        clearTrackedNodesByIds(state, action: PayloadAction<string[]>) {
            state.trackedNodes = state.trackedNodes.filter(t => !action.payload.some(id => id === t.id))
        },
        clearTrackedNodes(state) {
            state.trackedNodes = []
        }
    },
})

export const { addChatbotData, updateChatbotFlow, updateFormValidity, trackNewNode, updateTrackedNode, clearTrackedNodesByIds, clearTrackedNodes } = chatbotSlice.actions
export const hasNewEmptyNodesSelector = (state: RootState) => state.chatbot.trackedNodes?.some(n => n.isNew)
// export const hasDirtyNodesSelector = (state: RootState) => state.chatbot.trackedNodes?.some(n => n.isDirty)
export const isFromsValidSelector = (state: RootState) => !state.chatbot.trackedNodes?.some(n => !n.isValid)
export const trackedNodesSelector = (state: RootState) => state.chatbot.trackedNodes
export const trackedNodeSelector = (state: RootState, id: string) => state.chatbot.trackedNodes?.find(n => n.id === id)

export default chatbotSlice;