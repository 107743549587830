import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import localStore from 'store2';

import { IProfile } from 'models/profileModel';
import { getUserData } from 'services/user/userApi';
import { getAccountCredit } from 'services/accountCredit/accountCreditApi';

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
  const currentUser = localStore.get(process.env.REACT_APP_LOCAL_STORAGE_CURRENT_USER) as IProfile
  if (currentUser !== null && currentUser !== undefined) {
    return currentUser
  }
  const userData = await getUserData();
  return userData;
});

export const fetchAccountCredit = createAsyncThunk('account/fetchCredit', async () => {
  const credit = await getAccountCredit();
  return credit;
});

interface UserState {
  data: IProfile | null;
  currentCredit: number;
  loading: boolean;
  error: string | null;
}

const initialState: UserState = {
  data: null,
  currentCredit: 0.0000,
  loading: false,
  error: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserData: (state, action: PayloadAction<IProfile>) => {
      if (state.data) {
        state.data = { ...action.payload }
        localStore.set(process.env.REACT_APP_LOCAL_STORAGE_CURRENT_USER, action.payload)
      }
    },
    updateWebhookVerification: (state, action: PayloadAction<boolean>) => {
      if (state.data) {
        state.data.isWAWebhookVerified = action.payload
        localStore.set(process.env.REACT_APP_LOCAL_STORAGE_CURRENT_USER, state.data)
      }
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        localStore.set(process.env.REACT_APP_LOCAL_STORAGE_CURRENT_USER, action.payload)
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'An error occurred.';
      })
      .addCase(fetchAccountCredit.fulfilled, (state, action) => {
        state.loading = false;
        state.currentCredit = action.payload;
      })
  },
});

export const { updateUserData, updateWebhookVerification, setLoading } = userSlice.actions;

export default userSlice;
