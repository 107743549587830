import { UserManagerSettings, WebStorageStateStore } from "oidc-client-ts";

import { AUTHORITY, CLIENT_ID, REDIRECT_URI, SILENT_REDIRECT_URI, SCOPE, ACCESS_TOKEN_EXPIRATION_SECONDS } from 'utils/setting'

export const authConfig: UserManagerSettings = {
    authority: String(AUTHORITY),
    client_id: String(CLIENT_ID),
    redirect_uri: String(REDIRECT_URI),
    silent_redirect_uri: SILENT_REDIRECT_URI,
    automaticSilentRenew: true,
    response_type: 'code',
    scope: SCOPE,
    accessTokenExpiringNotificationTimeInSeconds: Number(ACCESS_TOKEN_EXPIRATION_SECONDS),
    userStore: new WebStorageStateStore({ store: window.localStorage }),
};