import React, { useEffect } from 'react';
import { signinSilentCallback } from 'utils/auth';

const SilentCallback = () => {
  useEffect(() => {
    signinSilentCallback();
  }, []);

  return <></>
}

export default SilentCallback;
