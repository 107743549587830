import { combineReducers } from '@reduxjs/toolkit'
import usersSlice from './features/user/userSlice';
import themeSlice from './features/theme/themeSlice';
import whatsAppTemplateSlice from './features/whatsAppTemplate/whatsAppTemplateSlice';
import chatbotSlice from './features/chatbot/chatbotSlice';
import visualBuilderSlice from './features/visualBuilder/visualBuilderSlice';

const rootReducer = combineReducers({
    theme: themeSlice.reducer,
    user: usersSlice.reducer,
    whatsAppTemplate: whatsAppTemplateSlice.reducer,
    chatbot: chatbotSlice.reducer,
    visualBuilder: visualBuilderSlice.reducer,
})

export default rootReducer;