import React from 'react'

import T from 'utils/useLocalizer'

interface Props {
    message?: string
}

const PageSpinner = ({ message }: Props) => {
    const resources = {
        message: message ? T(message) : ''
    }
    return (
        <div className='position-relative' style={{ minHeight: 'calc(100vh - 120px)' }}>
            <div className='position-absolute top-50 start-50 translate-middle text-center'>
                <div className='text-muted mb-2'>{resources.message}</div>
                <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    )
}

export default PageSpinner