import { createBrowserRouter, Navigate, } from "react-router-dom";
import loadable from "@loadable/component";

import { pageRoutes } from "./pageRoutes";
import SigninCallback from "pages/auth/SigninCallback";
import SilentCallback from "pages/auth/SilentCallback";

const Login = loadable(() => import("pages/auth/Login"));
const ProtectedRoute = loadable(() => import("components/router/ProtectedRoute"));
const MainLayout = loadable(() => import("components/layouts/MainLayout"));
const HomeLayout = loadable(() => import("components/layouts/HomeLayout"));
const ChatbotLayout = loadable(() => import("components/layouts/ChatbotLayout"));
const ErrorPage = loadable(() => import("pages/Error"));
const HomePage = loadable(() => import("pages/Home"));
const Profile = loadable(() => import("pages/Profile"));
const AccountCreditAdditions = loadable(() => import("pages/AccountCreditAdditions"));
const AccountPackages = loadable(() => import("pages/AccountPackages"));
const WhatsAppGuide = loadable(() => import("pages/WhatsAppGuide"));
const WhatsAppNumbers = loadable(() => import("pages/WhatsAppNumbers"));
const WhatsAppNumberNew = loadable(() => import("pages/WhatsAppNumberNew"));
const WhatsAppTemplate = loadable(() => import("pages/WhatsAppTemplate"));
const WhatsAppTemplateNew = loadable(() => import("pages/WhatsAppTemplateNew"));
const WhatsAppTemplateNewEdit = loadable(() => import("pages/WhatsAppTemplateNewEdit"));
const WhatsAppTemplateUpdate = loadable(() => import("pages/WhatsAppTemplateUpdate"));
const WhatsAppMessageLink = loadable(() => import("pages/WhatsAppMessageLink"));
const WhatsAppBroadcast = loadable(() => import("pages/WhatsAppBroadcast"));
const WhatsAppChannelVerification = loadable(() => import("pages/WhatsAppChannelVerification"));
const ApiKeys = loadable(() => import("pages/ApiKeys"));
const AddApiKey = loadable(() => import("pages/AddApiKey"));
const UpdateApiKey = loadable(() => import("pages/UpdateApiKey"));
const WebHookSettings = loadable(() => import("pages/WebHookSettings"));
const AddWebhookSetting = loadable(() => import("pages/WebHookSettingsNew"));
const UpdateWebhookSetting = loadable(() => import("pages/WebHookSettingsUpdate"));
const Chatbots = loadable(() => import("pages/Chatbots"));
const ChatbotGuide = loadable(() => import("pages/ChatbotGuide"));
const ChatbotBuilder = loadable(() => import("pages/ChatbotBuilder"));
const ChatbotSettings = loadable(() => import("pages/ChatbotSettings"));
const SmsSenderNames = loadable(() => import("pages/SmsSenderNames"));
const SmsSenderNameNew = loadable(() => import("pages/SmsSenderNameNew"));
const MessageLog = loadable(() => import("pages/MessageLog"));
const MessageExport = loadable(() => import("pages/MessageExport"));
const AddressBookCustomAttributes = loadable(() => import("pages/AddressBookCustomAttributes"));
const AddressBookContacts = loadable(() => import("pages/AddressBookContacts"));
const AddressBookContactNew = loadable(() => import("pages/AddressBookContactNew"));
const AddressBookContactUpdate = loadable(() => import("pages/AddressBookContactUpdate"));
const AddressBookContactUpload = loadable(() => import("pages/AddressBookContactUpload"));
const AddressBookContactGroups = loadable(() => import("pages/AddressBookContactGroups"));
const AddAddressBookGroup = loadable(() => import("pages/AddressBookGroupNew"));
const AddressBookGroupUpdate = loadable(() => import("pages/AddressBookGroupUpdate"));
const Campaigns = loadable(() => import("pages/Campaigns"));
const CampaignNew = loadable(() => import("pages/CampaignNew"));
const CampaignUpdate = loadable(() => import("pages/CampaignUpdate"));
const CampaignSummary= loadable(() => import("pages/CampaignReportSummary"));
const CampaignDetails= loadable(() => import("pages/CampaignReportDetails"));
const CampaignInformation= loadable(() => import("pages/CampaignDetails"));

export const router = createBrowserRouter([
    {
        path: "/",
        element: <MainLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <Navigate to={pageRoutes.home} />
            },
            {
                element: <ProtectedRoute><HomeLayout /></ProtectedRoute>,
                children: [
                    {
                        path: pageRoutes.home,
                        element: <HomePage />
                    },
                    {
                        path: pageRoutes.profile,
                        element: <Profile />
                    },
                    {
                        path: pageRoutes.accountCreditAdditions,
                        element: <AccountCreditAdditions />
                    },
                    {
                        path: pageRoutes.accountPackages,
                        element: <AccountPackages />
                    },
                    {
                        path: pageRoutes.whatsAppGuide,
                        element: <WhatsAppGuide />
                    },
                    {
                        path: pageRoutes.whatsAppTemplates,
                        element: <WhatsAppTemplate />
                    },
                    {
                        path: pageRoutes.whatsAppTemplateNew,
                        element: <WhatsAppTemplateNew />
                    },
                    {
                        path: pageRoutes.whatsAppTemplateNewEdit,
                        element: <WhatsAppTemplateNewEdit />
                    },
                    {
                        path: pageRoutes.whatsAppTemplateUpdate,
                        element: <WhatsAppTemplateUpdate />
                    },
                    {
                        path: pageRoutes.whatsAppNumbers,
                        element: <WhatsAppNumbers />
                    },
                    {
                        path: pageRoutes.whatsAppNumberNew,
                        element: <WhatsAppNumberNew />
                    },
                    {
                        path: pageRoutes.whatsAppMessageLink,
                        element: <WhatsAppMessageLink />
                    },
                    {
                        path: pageRoutes.whatsAppBroadcast,
                        element: <WhatsAppBroadcast />
                    },
                    {
                        path: pageRoutes.whatsAppChannelVerification,
                        element: <WhatsAppChannelVerification />,
                    },
                    {
                        path: pageRoutes.apiKeys,
                        element: <ApiKeys />,
                    },
                    {
                        path: pageRoutes.addApiKey,
                        element: <AddApiKey />,
                    },
                    {
                        path: pageRoutes.updateApiKey,
                        element: <UpdateApiKey />
                    },
                    {
                        path: pageRoutes.webHook,
                        element: <WebHookSettings />
                    },
                    {
                        path: pageRoutes.addWebhook,
                        element: <AddWebhookSetting />,
                    },
                    {
                        path: pageRoutes.updateWebhook,
                        element: <UpdateWebhookSetting />,
                    },
                    {
                        path: pageRoutes.chatbots,
                        element: <Chatbots />,
                    },
                    {
                        path: pageRoutes.chatbotGuide,
                        element: <ChatbotGuide />,
                    },
                    {
                        path: pageRoutes.smsSenderNames,
                        element: <SmsSenderNames />,
                    },
                    {
                        path: pageRoutes.smsSenderNameNew,
                        element: <SmsSenderNameNew />,
                    },
                    {
                        path: pageRoutes.messageLog,
                        element: <MessageLog />,
                    },
                    {
                        path: pageRoutes.messageExport,
                        element: <MessageExport />,
                    },
                    {
                        path: pageRoutes.customAttributes,
                        element: <AddressBookCustomAttributes />,
                    },
                    {
                        path: pageRoutes.contacts,
                        element: <AddressBookContacts />,
                    },
                    {
                        path: pageRoutes.contactNew,
                        element: <AddressBookContactNew />,
                    },
                    {
                        path: pageRoutes.contactUpdate,
                        element: <AddressBookContactUpdate />,
                    },
                    {
                        path: pageRoutes.contactUpload,
                        element: <AddressBookContactUpload />,
                    },
                    {
                        path: pageRoutes.contactGroups,
                        element: <AddressBookContactGroups />,
                    },
                    {
                        path: pageRoutes.addContactGroups,
                        element: <AddAddressBookGroup />
                    },
                    {
                        path: pageRoutes.updateContactGroups,
                        element: <AddressBookGroupUpdate />
                    },
                    {
                        path: pageRoutes.campaigns,
                        element: <Campaigns />
                    },
                    {
                        path: pageRoutes.campaignNew,
                        element: <CampaignNew />
                    },
                    {
                        path: pageRoutes.campaignUpdate,
                        element: <CampaignUpdate />
                    },
                    {
                        path: pageRoutes.campaignInformation,
                        element: <CampaignInformation />
                    }
                    ,
                    {
                        path: pageRoutes.campaignSummery,
                        element: <CampaignSummary />
                    },
                    {
                        path: pageRoutes.campaignDetails,
                        element: <CampaignDetails />
                    }
                ]
            },
            {
                path: pageRoutes.chatbotBuilderPage,
                element: <ProtectedRoute><ChatbotLayout /></ProtectedRoute>,
                children: [
                    {
                        path: pageRoutes.chatbotBuilder,
                        element: <ChatbotBuilder />,
                    },
                    {
                        path: pageRoutes.chatbotBuilderAttributes,
                        element: <div>Attributes</div>,
                    },
                    {
                        path: pageRoutes.chatbotBuilderSettings,
                        element: <ChatbotSettings />,
                    },
                ]
            },
        ]
    },
    {
        path: '/login',
        element: <Login />,
    },
    {
        path: '/callback',
        element: <SigninCallback />
    },
    {
        path: '/silent-callback',
        element: <SilentCallback />
    },
]);