import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export interface VisualBuilderState {
    showBotSendMenu: boolean,
    showBotSendMoreActionsMenu: boolean,
    showUserInputMenu: boolean,
    showUserInputMoreActionsMenu: boolean,
    botSendMenu: any,
    botSendMoreActionsMenu: any,
    userInputMenu: any,
    userInputMoreActionsMenu: any,
}

const visualBuilderSlice = createSlice({
    name: 'visualBuilder',
    initialState: {} as VisualBuilderState,
    reducers: {
        toggleBotSendMenu(state, action: PayloadAction<boolean>) {
            state.showBotSendMenu = action.payload
        },
        setBotSendMenu(state, action: PayloadAction<any>) {
            state.botSendMenu = action.payload
        },
        toggleBotSendMoreActionsMenu(state, action: PayloadAction<boolean>) {
            state.showBotSendMoreActionsMenu = action.payload
        },
        setBotSendMoreActionsMenu(state, action: PayloadAction<any>) {
            state.botSendMoreActionsMenu = action.payload
        },
        toggleUserInputMenu(state, action: PayloadAction<boolean>) {
            state.showUserInputMenu = action.payload
        },
        setUserInputMenu(state, action: PayloadAction<any>) {
            state.userInputMenu = action.payload
        },
        toggleUserInputMoreActionsMenu(state, action: PayloadAction<boolean>) {
            state.showUserInputMoreActionsMenu = action.payload
        },
        setUserInputMoreActionsMenu(state, action: PayloadAction<any>) {
            state.userInputMoreActionsMenu = action.payload
        },
        closeAllMenus(state) {
            state.showBotSendMenu = false
            state.showUserInputMenu = false
            state.botSendMenu = null
            state.userInputMenu = null
            state.showBotSendMoreActionsMenu = false
            state.showUserInputMoreActionsMenu = false
            state.botSendMoreActionsMenu = null
            state.userInputMoreActionsMenu = null
        }
    },
})

export const {
    toggleBotSendMenu, toggleBotSendMoreActionsMenu, setBotSendMenu, setBotSendMoreActionsMenu,
    toggleUserInputMenu, setUserInputMenu, toggleUserInputMoreActionsMenu, setUserInputMoreActionsMenu,
    closeAllMenus
} = visualBuilderSlice.actions

export default visualBuilderSlice;