export const pageRoutes = {
    root: '/',
    home: '/home',
    profile: '/profile',
    accountCreditAdditions: '/account-credit-additions',
    accountPackages: '/account-packages',
    whatsAppGuide: '/whatsapp/guide-and-setup',
    whatsAppTemplates: '/whatsapp/templates',
    whatsAppTemplateNew: '/whatsapp/new-template',
    whatsAppTemplateNewEdit: '/whatsapp/new-template-edit',
    whatsAppTemplateUpdate: '/whatsapp/edit-template/:templateId',
    whatsAppNumbers: '/whatsapp/phone-numbers',
    whatsAppNumberNew: '/whatsapp/new-number',
    whatsAppMessageLink: '/whatsapp/message-link',
    whatsAppBroadcast: '/whatsapp/broadcast',
    whatsAppChannelVerification: '/whatsapp/channel-verification',
    apiKeys : '/developer-tools/apikeys',
    addApiKey:'/developer-tools/new-apikeys',
    updateApiKey: '/developer-tools/edit-apikeys/:keyId',
    webHook:'/developer-tools/webhook-settings',
    addWebhook: '/developer-tools/new-webhook-settings',
    updateWebhook: '/developer-tools/update-webhook-settings/:id',
    chatbots: '/conversation-studio/chatbots',
    chatbotGuide: '/conversation-studio/chatbotGuide',
    chatbotBuilderPage: '/conversation-studio/visual-builder',
    chatbotBuilder: '/conversation-studio/visual-builder/:chatbotId',
    chatbotBuilderAttributes: '/conversation-studio/visual-builder/attributes/:chatbotId',
    chatbotBuilderSettings: '/conversation-studio/visual-builder/settings/:chatbotId',
    smsSenderNames: '/sms/sender-names',
    smsSenderNameNew: '/sms/new-sender-name',
    messageLog:'/reports/message-log',
    messageExport:'/reports/message-export',
    customAttributes: '/address-book/custom-attributes',
    contacts: '/address-book/contacts',
    contactGroups: '/address-book/contact-groups',
    contactNew: '/address-book/new-contact',
    contactUpdate: '/address-book/update-contact/:contactId',
    contactUpload: '/address-book/upload-contacts',
    updateContactGroups: '/address-book/edit-contact-groups/:id',
    addContactGroups:'/address-book/new-contact-groups',
    campaigns:'/campaigns',
    campaignNew:'/campaigns/new-campaign',
    campaignUpdate:'/campaigns/update-campaign/:campaignId',
    campaignInformation:'/campaigns/:campaignId',
    campaignSummery:'/campaigns/campaign-summary',
    campaignDetails:'/campaigns/campaign-details/:campaignId'
}