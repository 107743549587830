import i18next from 'i18next';

import useNotify from 'utils/useNotify'
import T from 'utils/useLocalizer'

/** handles API thrown errors */
const useError = () => {
    const { notifyError } = useNotify();

    const resources = {
        validationError: T('server.msg.validation'),
        accessDeniedError: T('server.msg.forbidden'),
        notFoundError: T('server.msg.contentnotfound'),
        generalError: T('server.msg.general'),
    }

    const getLocalizedBusinessErrorMsg = (exceptionName: string) => {
        return i18next.t(`server.msg.${exceptionName.toLowerCase()}`);
    }

    const throwException = (error: any, customeKey?: string) => {
        if (error?.response) {
            switch (error.response?.status) {
                case 400: return notifyServerError(customeKey ?? resources.validationError)
                case 403: return notifyServerError(customeKey ?? resources.accessDeniedError)
                case 404: return notifyServerError(customeKey ?? resources.notFoundError)
                case 409: return notifyServerError(customeKey ?? getLocalizedBusinessErrorMsg(error.response?.data?.type))
                default: return notifyServerError(customeKey ?? resources.generalError)
            }
        }
    }

    const notifyServerError = (message: string) => notifyError(message, { position: 'top-center' })

    return { throwException }
}

export default useError;