import localStore from "store2";

import { IKeyValue } from "models/commonModel";

export const getSetting = (key: string): string => {
    const configs: IKeyValue<string> = localStore.get(process.env.REACT_APP_LOCAL_STORAGE_SETTINGS);
    if (configs) {
        if (configs[key]) {
            return configs[key];
        }
    }
    return process.env[key]!!;
};

export const API_BASE_URI = getSetting('REACT_APP_API_BASE_URI');
export const RESOURCES_EXPIRATION_TIME = +getSetting('REACT_APP_RESOURCES_EXPIRATION_TIME');
export const LOAD_RESOURCES_PATH = getSetting('REACT_APP_LOAD_RESOURCES_PATH');
export const RESOURCES_VERSION = getSetting('REACT_APP_RESOURCES_VERSION');
export const AUTHORITY = getSetting('REACT_APP_AUTHORITY');
export const CLIENT_ID = getSetting('REACT_APP_CLIENT_ID');
export const REDIRECT_URI = getSetting('REACT_APP_REDIRECT_URI');
export const SILENT_REDIRECT_URI = getSetting('REACT_APP_SILENT_REDIRECT_URI');
export const SCOPE = getSetting('REACT_APP_SCOPE');
export const ACCESS_TOKEN_EXPIRATION_SECONDS = +getSetting('REACT_APP_ACCESS_TOKEN_EXPIRING_NOTIFICATION_TIME');
export const WHATSAPP_TEMPLATES_PAGE_SIZE = +getSetting('REACT_APP_WHATSAPP_TEMPLATES_PAGE_SIZE');
export const LIST_DEFAULT_STALE_TIME = +getSetting('REACT_APP_LIST_DEFAULT_STALE_TIME');
export const TEMPLATE_HEADER_FILE_MAX_SIZE = +getSetting('REACT_APP_TEMPLATE_HEADER_FILE_MAX_SIZE');
export const CHATBOT_SESSION_DEFAULT_LIFETIME = +getSetting('REACT_APP_CHATBOT_SESSION_DEFAULT_LIFETIME');
export const SMS_SENDER_NAME_REGEX_PATTERN = getSetting('REACT_APP_SMS_SENDER_NAME_REGEX_PATTERN');
export const MESSAGE_LINK_PREFILLED_MAX_LENGTH = getSetting('REACT_APP_MESSAGE_LINK_PREFILLED_MAX_LENGTH');
export const ACCOUNT_CREDIT_ADDITIONS_PAGE_SIZE = +getSetting('REACT_APP_ACCOUNT_CREDIT_ADDITIONS_PAGE_SIZE');
export const ACCOUNT_PACKAGE_PAGE_SIZE = +getSetting('REACT_APP_ACCOUNT_PACKAGE_PAGE_SIZE');
export const ADDRESS_BOOK_CONTACTS_PAGE_SIZE = +getSetting('REACT_APP_ADDRESS_BOOK_CONTACTS_PAGE_SIZE');
export const ADDRESS_BOOK_CONTACTS_UPLOAD_SAMPLE_FILE_URL = getSetting('REACT_APP_ADDRESS_BOOK_CONTACTS_UPLOAD_SAMPLE_FILE_URL');
export const CAMPAIGN_PAGE_SIZE = +getSetting('REACT_APP_CAMPAIGNS_PAGE_SIZE');