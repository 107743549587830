import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Offline, Online } from "react-detect-offline";

import { router } from 'config/router/router';
import { store } from 'store/storeConfig';
import OfflinePage from 'pages/OfflinePage';

import 'reactflow/dist/style.css';
import 'react-toastify/dist/ReactToastify.css';
import 'simplebar-react/dist/simplebar.min.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

function App() {
  return (<React.Fragment>
    <Online polling={false}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ReactQueryDevtools initialIsOpen={false} />
          <ToastContainer />
          <RouterProvider router={router} />
        </Provider>
      </QueryClientProvider>
    </Online>

    <Offline polling={false}><OfflinePage /></Offline>
  </React.Fragment>);
}

export default App;
