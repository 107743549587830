import api from 'config/api/protectedApi';
import { apiRoutes } from "config/api/apiRoutes";
import { IPaginatedList } from 'models/commonModel';
import { IAccountCreditAddition } from 'models/accountCreditModel';

export function getAccountCredit(): Promise<number> {
    return api.get(apiRoutes.getAccountCurrentCredit)
        .then(response => response.data)
        .catch(error => { throw error });
}

export const useAccountCreditApi = () => {
    async function getCreditAdditionsList(pageIndex: number, pageSize: number): Promise<IPaginatedList<IAccountCreditAddition>> {
        return api.get(apiRoutes.getAccountCreditAdditionHistory, {
            params: {
                pageIndex: pageIndex,
                pageSize: pageSize,
            }
        })
            .then(response => response.data)
            .catch(error => {
                throw error
            });
    }

    return { getCreditAdditionsList }
}