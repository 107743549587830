import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { NewTemplateFormData } from 'models/whatsAppTemplateModel'


export interface WhatsAppTemplateState {
    data: NewTemplateFormData | null,
    isEditable: boolean
}

const whatsAppTemplateSlice = createSlice({
    name: 'whatsAppTemplate',
    initialState: {
        data: null,
        isEditable: true
    } as WhatsAppTemplateState,
    reducers: {
        addNewTeplateFormData(state, action: PayloadAction<NewTemplateFormData>) {
            state.data = action.payload
        },
        setIsEditable(state, action: PayloadAction<boolean>) {
            state.isEditable = action.payload
        },
    },
})

export const { addNewTeplateFormData, setIsEditable } = whatsAppTemplateSlice.actions

export default whatsAppTemplateSlice;