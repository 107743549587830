import React, { useEffect } from 'react';

import PageSpinner from 'components/ui/spinner/PageSpinner';
import { signinCallback } from 'utils/auth';

const SigninCallback = () => {

  useEffect(() => {
    signinCallback()
  }, []);

  return <PageSpinner />
};

export default SigninCallback;