export const apiRoutes = {
  getCountries: 'Common/GetCountriesList',
  getLanguages: 'Common/GetLanguagesList',
  getTimezones: 'Common/GetTimezoneList',
  validateUrl: 'Common/ValidateUrl',
  getUserData: 'Profile/GetBusinessAccount',
  getProfileInfo: 'Profile/GetBusinessAccountInfo',
  updateProfileInfo: 'Profile/UpdateBusinessAccountInfo',
  getAccountCurrentCredit: 'AccountCredit/GetCurrentCredit',
  getAccountCreditAdditionHistory: 'AccountCredit/GetCreditAdditionHistory',
  getAccountPackages: 'AccountPackage/GetAccountPackages',
  requestEmailVerificationCode: 'Profile/RequestEmailVerificationCode',
  verifyProfileEmail: 'Profile/VerifyBusinessAccountEmail',
  requestMobileVerificationCode: 'Profile/RequestMobileVerificationCode',
  verifyProfileMobile: 'Profile/VerifyBusinessAccountMobile',
  activateWhatsAppChannel: 'WhatsAppChannel/ActivateWhatsAppChannel',
  whatsAppWebhookVerificationStatus: 'WhatsAppChannel/GetWebhookVerificationStatus',
  completeWhatsAppChannelOnboarding: 'WhatsAppChannel/CompleteWhatsAppChannelOnboarding',
  getWhatsAppChannel: 'WhatsAppChannel/GetWhatsAppChannel',
  getWhatsAppNumbers: 'WhatsAppNumber/GetWhatsAppPhoneNumbers',
  getWhatsAppNumbersByStatusIds: 'WhatsAppNumber/GetWhatsAppPhoneNumbersByStatusIds',
  createWhatsAppNumber: 'WhatsAppNumber/CreateWhatsAppPhoneNumber',
  verifyWhatsAppNumber: 'WhatsAppNumber/VerifyWhatsAppPhoneNumber',
  requestWhatsAppNumberVerificationCode: 'WhatsAppNumber/RequestWhatsAppPhoneNumberVerification',
  syncNumbers: 'WhatsAppNumber/SyncWhatsAppNumbers',
  getWhatsAppTemplates: 'WhatsAppTemplate/GetWhatsAppTemplates',
  getWhatsAppTemplateLookups: 'WhatsAppTemplate/GetWhatsAppTemplateLookups',
  getWhatsAppTemplateById: 'WhatsAppTemplate/GetWhatsAppTemplateById',
  getTemplateLanguages: 'WhatsAppTemplate/GetTemplateLanguages',
  getTemplateCategories: 'WhatsAppTemplate/GetTemplateCategories',
  getTemplateStatuses: 'WhatsAppTemplate/GetTemplateStatuses',
  createTemplate: 'WhatsAppTemplate/CreateTemplate',
  updateTemplate: 'WhatsAppTemplate/UpdateTemplate',
  deleteTemplate: 'WhatsAppTemplate/DeleteTemplate',
  syncTemplate: 'WhatsAppTemplate/SyncWhatsAppTemplates',
  uploadTemplateMediaType: 'WhatsAppTemplate/UploadTemplateMediaFile',
  getWhatsAppMessageLinks: 'WhatsAppMessageLink/GetWhatsAppMessageLinks',
  createWhatsAppMessageLink: 'WhatsAppMessageLink/CreateWhatsAppMessageLink',
  updateWhatsAppMessageLink: 'WhatsAppMessageLink/UpdateWhatsAppMessageLink',
  deleteWhatsAppMessageLink: 'WhatsAppMessageLink/DeleteWhatsAppMessageLink',
  processWhatsAppBroadcastNumbersFile: 'WhatsAppBroadcast/ProcessWhatsAppBroadcastNumbersFile',
  createWhatsAppBroadcast: 'WhatsAppBroadcast/CreateWhatsAppBroadcast',
  getApiKeys: 'APIKeys/GetAPIKeys',
  getApiScope: 'APIKeys/GetAPIKeysScope',
  createApiKey: 'APIKeys/CreateAPIKey',
  getApiKeyById: 'APIKeys/GetApiKeyById',
  updateApiKey: 'APIKeys/UpdateAPIKey',
  getWHGroupEvents: 'WebHook/GetWHGroupEvents',
  getWHEndPointEventsList: 'WebHook/GetWHEndPointEventsList',
  getWHInfByEndPointId: 'WebHook/GetWHInfoByEndPointId',
  createWHEndPointSettings: 'WebHook/CreateWHEndPoint',
  updateWHEndPointSettings: 'WebHook/UpdateWHEndPoint',
  deleteWHEndPointSettings: 'WebHook/DeleteWHEndPoint',
  getChatbots: 'Chatbot/GetChatbots',
  getChatbotById: 'Chatbot/GetChatbotById',
  createChatbot: 'Chatbot/CreateChatbot',
  updateChatbotSettings: 'Chatbot/UpdateChatbotSettings',
  updateChatbotVisualBuilder: 'Chatbot/UpdateChatbotVisualBuilder',
  updateChatbotStatus: 'Chatbot/UpdateChatbotStatus',
  deleteChatbotStatus: 'Chatbot/DeleteChatbot',
  getChatbotSenders: 'Chatbot/GetChatbotSenders',
  getKeywordCategories: 'Chatbot/GetKeywordCategories',
  getSmsSenderNames: 'SmsSenderName/GetSmsSenderNames',
  getApprovedSmsSenderNames: 'SmsSenderName/GetApprovedSmsSenderNames',
  getSmsSenderNameById: 'SmsSenderName/GetSmsSenderNameById',
  createSmsSenderNameRequest: 'SmsSenderName/CreateSmsSenderNameRequest',
  setSenderNameAsDefault: 'SmsSenderName/SetSenderNameAsDefault',
  deleteSenderName: 'SmsSenderName/DeleteSenderName',
  getCountryRequiredDocs: 'SmsSenderName/GetCountryRequiredDocuments',
  getTrafficTypes: 'SmsSenderName/GetTrafficTypesList',
  getMessageLog:'Reports/GetMessageLog',
  createRequestMessageExport:'Reports/CreateRequestMessageExport',
  getAddressBookContacts: 'AddressBook/GetAddressBookContacts',
  getAddressBookContactById: 'AddressBook/GetAddressBookContactById',
  createContact: 'AddressBook/CreateAddressBookContact',
  updateContact: 'AddressBook/UpdateAddressBookContact',
  deleteContact: 'AddressBook/DeleteAddressBookContact',
  proccessAddressBookContactsHeadersFile: 'AddressBook/ProccessAddressBookContactsHeadersFile',
  createUploadedAddressBookContacts: 'AddressBook/CreateUploadedAddressBookContacts',
  getAddressBookCustomAttributes: 'AddressBook/GetABCustomAttributes',
  getAddressBookCustomAttributeById: "AddressBook/GetABCustomAttributeById",
  createAddressBookCustomAttribute: "AddressBook/CreateABCustomAttribute",
  updateAddressBookCustomAttribute: "AddressBook/UpdateABCustomAttribute",
  deleteAddressBookCustomAttribute: "AddressBook/DeleteABCustomAttribute",
  getDataTypes: "AddressBook/GetAddressBookDataTypes",
  getAddressBookGroups: 'AddressBook/GetAddressBookGroups',
  deleteAddressBookGroup: 'AddressBook/DeleteAddressBookGroup',
  createAddressBookGroup:'AddressBook/CreateAddressBookGroup',
  updateAddressBookGroup:'AddressBook/UpdateAddressBookGroup',
  getAddressBookGroupByGroupId:'AddressBook/GetAddressBookGroupByGroupId',
  deleteAddressBookGroupContacts: 'AddressBook/DeleteAddressBookGroupContacts',
  getAddressBookContactsByGroupId: 'AddressBook/GetAddressBookContactsByGroupId',
  getCampains: 'Campaign/GetCampaigns',
  loadCampaign: 'Campaign/LoadCampaign',
  getCampainById: 'Campaign/GetCampaignById',
  getCampainsStatus: 'Campaign/GetCampaignsStatus',
  getCampainsFailureReason: 'Campaign/GetCampaignsFailureReasons',
  deleteCampaign: 'Campaign/DeleteCampaign',
  createCampaign: 'Campaign/CreateCampaign',
  updateCampaign: 'Campaign/UpdateCampaign',
  processCampaignRecipientFile: 'Campaign/ProcessCampaignRecipientFile',
  deleteCampaignRecipientFile: 'Campaign/DeleteCampaignRecipientFile',
  processCampaignRecipientText: 'Campaign/ProcessCampaignRecipientText',
  cancelCampaign: 'Campaign/CancelCampaign',
  getCampainsLookup: 'Campaign/GetCampaignsLookup',
  loadCampaignSummary: 'Campaign/LoadCampaignSummary',
  getCampaignMessageDetails:'Reports/GetCampaignMessageReport',
  GetCampaignUsedList: 'Campaign/GetCampaignUsedList',
};