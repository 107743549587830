import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import LocalStorageBackend from "i18next-localstorage-backend";
import ChainedBackend from "i18next-chained-backend";

import { RESOURCES_VERSION, RESOURCES_EXPIRATION_TIME, LOAD_RESOURCES_PATH, API_BASE_URI } from 'utils/setting'

export const backendOptions = {
  crossDomain: true,
  defaultVersion: RESOURCES_VERSION,
  expirationTime: RESOURCES_EXPIRATION_TIME,
  store: window.localStorage,
  prefix: `trans_${RESOURCES_VERSION}_`,
};

i18n
  .use(ChainedBackend)
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  //.use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    initImmediate: false,
    fallbackLng: 'en',
    debug: false,
    react: {
      useSuspense: true
    },
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      backends: [
        LocalStorageBackend,
        Backend
      ],
      backendOptions: [
        backendOptions,
        {
          load: 'languageOnly',
          loadPath: `${API_BASE_URI}${LOAD_RESOURCES_PATH}`
        }
      ]
    }
  });


export default i18n;