import api from 'config/api/protectedApi';
import { apiRoutes } from 'config/api/apiRoutes';
import useError from 'utils/useError';
import { IProfile } from 'models/profileModel';

export async function getUserData(): Promise<IProfile> {
    return api.get(apiRoutes.getUserData)
        .then(response => response.data)
        .catch(error => { throw error });
}

export const useProfileApi = () => {
    const { throwException } = useError()

    async function getProfileInfo(): Promise<IProfile> {
        return api.get(apiRoutes.getProfileInfo)
            .then(response => response.data)
            .catch(error => { throw error });
    }

    async function updateProfileInfo(profile: IProfile): Promise<IProfile> {
        const data = { ...profile };

        return api.put(apiRoutes.updateProfileInfo, data)
            .then(() => profile)
            .catch(error => {
                throwException(error)
                throw error
            });
    }

    async function requestEmailVerificationCode(email: string): Promise<boolean> {
        const data = {
            newEmail: email
        }
        return api.post(apiRoutes.requestEmailVerificationCode, data)
            .then(() => true)
            .catch(error => {
                throwException(error)
                throw error
            });
    }

    async function verifyProfileEmail(email: string, verificationCode: string): Promise<string> {
        const data = {
            newEmail: email,
            verificationCode: verificationCode
        }
        return api.post(apiRoutes.verifyProfileEmail, data)
            .then((response) => {
                const numberId = response.data as string
                return numberId
            })
            .catch(error => {
                throwException(error)
                throw error
            });
    }



    async function requestMobileVerificationCode(fullNumber: string): Promise<boolean> {
        const data = {
            fullNumber: fullNumber
        }
        return api.post(apiRoutes.requestMobileVerificationCode, data)
            .then(() => true)
            .catch(error => {
                throwException(error)
                throw error
            });
    }

    async function verifyProfileMobile(mobileNumber: string, mobileCountryId: number, fullMobileNumber: string, verificationCode: string): Promise<string> {
        const data = {
            mobileNumber: mobileNumber,
            mobileCountryId: mobileCountryId,
            fullMobileNumber: fullMobileNumber,
            verificationCode: verificationCode
        }
        return api.post(apiRoutes.verifyProfileMobile, data)
            .then((response) => {
                const numberId = response.data as string
                return numberId
            })
            .catch(error => {
                throwException(error)
                throw error
            });
    }

    return {
        getProfileInfo, updateProfileInfo,
        requestEmailVerificationCode, verifyProfileEmail,
        requestMobileVerificationCode, verifyProfileMobile
    }
} 