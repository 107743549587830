import { ToastContent, ToastOptions, toast } from "react-toastify";


const useNotify = () => {
    const defaultOptions: ToastOptions<{}> = {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false
    }

    const notifyError = (content: ToastContent, options?: ToastOptions) => {
        return toast.error(content, {...defaultOptions, ...options})
    }
    
    const notifySuccess = (content: ToastContent, options?: ToastOptions) => {
        return toast.success(content, {...defaultOptions, ...options})
    }
    
    const notifyInfo = (content: ToastContent, options?: ToastOptions) => {
        return toast.info(content, {...defaultOptions, ...options})
    }

    return { notifyError, notifySuccess, notifyInfo }
}

export default useNotify
